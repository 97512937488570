import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { User } from '@shared/models/user.model';
import { firstValueFrom } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Intercom: any;

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public user: User;
    public userPromise: Promise<User>;

    constructor(private readonly http: HttpClient) {}

    private initIntercom(): void {
        Intercom('boot', {
            app_id: 'vc7jxmzv',
            user_id: this.user.externalId,
            name: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.email
        });
    }

    /**
     * Load user only once
     * @param accountSlug
     * @param brandSlug
     */
    public async getUser(accountSlug?: string, brandSlug?: string): Promise<User> {
        // Can't load user without account and brand
        if ((!accountSlug || !brandSlug) && !this.userPromise) {
            throw new Error(`Can't load user without providing account and brand`);
        }

        this.userPromise = this.userPromise || this.loadUser(accountSlug, brandSlug);

        return this.userPromise;
    }

    public getUserByActivatedRouteSnapshot(route: ActivatedRouteSnapshot): Promise<User> {
        const params: Params = this.getParams(route);

        return this.getUser(params['accountSlug'], params['brandSlug']);
    }

    private async loadUser(accountSlug: string, brandSlug: string): Promise<User> {
        const currentUsersUrl =
            AppConfig.config.STAGE === 'production'
                ? `${AppConfig.config.B2_URL}/api/v2/${accountSlug}/${brandSlug}/users/current`
                : `${AppConfig.config.ACCOUNT_ACCESS_URL}/api/${accountSlug}/${brandSlug}/users/current`;

        const response = await firstValueFrom(this.http.get(currentUsersUrl));
        this.user = new User().deserialize(response);

        this.initIntercom();

        return this.user;
    }

    public reload(): Promise<User> {
        this.userPromise = this.loadUser(this.user.account.slug, this.user.brand.slug);

        return this.userPromise;
    }

    public isLoggedIn(): boolean {
        return this.user && !this.user.anonymous;
    }

    public setAnonymous(email: string): void {
        this.user.email = email;
        localStorage.setItem('email', email);
    }
    public logOut(): void {
        // api/v1/s/logout logs out from cookie and oidc logins
        window.location.href = `${AppConfig.config.B2_URL}/api/v1/s/logout`;
    }

    public hasFeature(feature: string): boolean {
        if (!this.user || !this.user.account) {
            return false;
        }
        return this.user.account.features.some((f: string) => f === feature);
    }

    public hasFeatures(features: string[] = [], allMustMatch: boolean = false): boolean {
        if (allMustMatch) {
            return features.every((feature: string) => {
                return this.hasFeature(feature);
            });
        }

        return features.some((feature: string) => {
            return this.hasFeature(feature);
        });
    }

    public isCMPUser(): boolean {
        return this.hasFeature(SessionService.FEATURES.CAMPAIGNMANAGER);
    }

    public hasRole(role: string): boolean {
        if (this.user) {
            return this.user.isEmployee || this.user.role.name === role;
        }
        return false;
    }
    public goAnalytics(): void {
        window.location.href = `${AppConfig.config.ANALYTICS_FRONTEND_URL}/${this.user.account.slug}/${this.user.brand.slug}/analytics`;
	}

    public gotoA1Url(url: string): void {
        window.location.href = `${AppConfig.config.B2_URL}/${this.user.account.slug}/${this.user.brand.slug}/${url}`;
    }

    public gotoA1UrlNewTab(url: string): void {
        window.open(`${AppConfig.config.B2_URL}/${this.user.account.slug}/${this.user.brand.slug}/${url}`, '_blank');
    }

    public gotoA1Dashboard(url: string = ''): void {
        window.location.href = `${AppConfig.config.B2_URL}/Dashboard/${this.user.account.slug}/${url}`;
    }

    public gotoA1Settings(url: string = '', accountSlug: string = ''): void {
        window.location.href = `${AppConfig.config.B2_URL}/Settings/${
            accountSlug ? accountSlug : this.user.account.slug
        }/${url}`;
    }

    public gotoA1BrandSettings(tab: string = 'Overview'): void {
        window.location.href = `${AppConfig.config.B2_URL}/Settings/${this.user.account.slug}/Brands/${this.user.brand.slug}/${tab}`;
        // Should this work anymore?
        // this.gotoA1Dashboard(`Account/${this.user.account.slug}/${tab}`);
    }

    public static FEATURES: { [key: string]: string } = {
        ADVANCEDFORMATS: 'AdvancedFormats',
        ANALYTICS: 'Analytics',
        APPROVE: 'Approve',
        BANNERSETDONEMARK: 'BannersetDoneMark',
        BARBANDWIDTH: 'BarBandwidth',
        BARIMPRESSIONS: 'BarImpressions',
        BARRECURLY: 'BarRecurly',
        BARRECURLYV2: 'BarRecurlyV2',
        CAMPAIGNMANAGER: 'CampaignManager',
        SOCIALCAMPAIGNMANAGER: 'SocialCampaignManager',
        SOCIALCAMPAIGNMANAGER_NETWORKS_TIKTOK: 'SocialCampaignManagerNetworks.TikTok',
        SOCIALCAMPAIGNMANAGER_NETWORKS_LINKEDIN: 'SocialCampaignManagerNetworks.LinkedIn',
        SOCIALDYNAMICADVERTISING: 'SocialDynamicAdvertising',
        COMMENTS: 'Comments',
        CPMIMPRESSIONS: 'CpmImpressions',
        CUSTOMASSETS: 'CustomAssets',
        CUSTOMCSS: 'CustomCss',
        CUSTOMEMBEDCODES: 'CustomEmbedCodes',
        DASHBOARDACCOUNTSETTINGS: 'DashboardAccountSettings',
        DASHBOARDBRAND: 'DashboardBrand',
        DASHBOARDBRANDSETTINGS: 'DashboardBrandSettings',
        DASHBOARDREPORTS: 'DashboardReports',
        DASHBOARDUSERS: 'DashboardUsers',
        DELETECOMMENTS: 'DeleteComments',
        DIRECTPUBLISHING: 'DirectPublishing',
        EXTERNALTRANSLATIONS: 'ExternalTranslations',
        FEATURE_ANALYTICS_DEMO_DATA: 'AnalyticsDemoData',
        FEEDS: 'Feeds',
        FEEDSAUTOMATED: 'FeedsAutomated',
        FEEDSLIVE: 'FeedsLive',
        INTRIAL: 'InTrial',
        INVOICEAGENCY: 'InvoiceAgency',
        INVOICEENTERPRISE: 'InvoiceEnterprise',
        INVOICERECURLY: 'InvoiceRecurly',
        LABELS: 'Labels',
        LANDINGPAGE: 'LandingPage',
        LANDINGPAGEEDITABLECNAMES: 'LandingPageEditableCnames',
        LOCK: 'Lock',
        MESSAGESYSTEM: 'MessageSystem',
        MULTIPLEBRANDS: 'MultipleBrands',
        MULTIPLEUSERS: 'MultipleUsers',
        NOBRANDING: 'NoBranding',
        NOHEADER: 'NoHeader',
        PAYPERFALLBACKDOWNLOAD: 'PayPerFallbackDownload',
        REQUIREPASSWORDFORNEWBRANDS: 'RequirePasswordForNewBrands',
        SCHEDULING: 'Scheduling',
        SETTINGS: 'Settings',
        STORE: 'Store',
        STUDIO: 'Studio',
        DISABLEBANNERBUILDER: 'DisableBannerBuilder',
        PUBLISHER: 'Publisher',
        TRACKINGPIXELS: 'TrackingPixels',
        TWOFACTORAUTH: 'TwoFactorAuthentication',
        ADVANCEDPASSWORDPOLICY: 'AdvancedPasswordPolicy',
        WELCOMEMESSAGE: 'WelcomeMessage',
        DEFAULTVIEW: 'DefaultView',
        BRANDMANAGEMENT: 'BrandManagement',
        UPSELLVIEWS: 'UpsellViews',
        HIDEBANNERBUILDER: 'HideBannerBuilder'
    };

    public getParams(route: ActivatedRouteSnapshot): Params {
        // TODO: This is a non standard way of getting route params because
        // the ActivatedRoute in this context have no params populated.
        let result: Params = Object.assign({}, route.params);
        let tmp: ActivatedRouteSnapshot = route.firstChild;
        while (tmp) {
            if (tmp && tmp.params) {
                result = Object.assign(result, tmp.params);
            }
            tmp = tmp.firstChild;
        }
        tmp = route.parent;
        while (tmp) {
            if (tmp && tmp.params) {
                result = Object.assign(result, tmp.params);
            }
            tmp = tmp.parent;
        }
        return result;
    }
}
