import { IAppConfig } from './app.config.type';

export const config: IAppConfig = {
    BUILD_VERSION: 'd1dbff94e5aa4636b83633ea33362430707c3a70',
    STAGE: 'sandbox',
    APP_URL: 'https://sandbox-home.bannerflow.com',
    IDP_URL: 'https://sandbox-idp.bannerflow.com',
    B2_URL: 'https://sandbox-app.bannerflow.com',
    BRAND_MANAGER: 'https://sandbox-api.bannerflow.com/brand-manager',
    STUDIO_URL: 'https://sandbox-studio.bannerflow.com',
    SAPI_URL: 'https://sandbox-api.bannerflow.com/studio',
    CAMPAIGN_SERVICE_URL: 'https://sandbox-api.bannerflow.com/cs',
    PUBLISH_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ps',
    MIGRATION_SERVICE_URL: 'https://sandbox-migrationservice.bannerflow.com',
    ANALYTICS_BACKEND_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ab',
    CAMPAIGN_MANAGER_URL: 'https://sandbox-cm.bannerflow.com',
    AD_SERVICE_URL: 'https://c.sandbox-bannerflow.net',
    AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING:
        'Endpoint=https://bf-shared-sandbox-ac.azconfig.io;Id=+d6H-l8-s0:ZDsMi4u4B3bL3Y9VAexD;Secret=kycnl5jmUghCUDu70c/LdKSinLqeEtkZE08EvTEsLK4=',
    SOCIAL_CAMPAIGN_SERVICE_URL: 'https://sandbox-scs.bannerflow.com',
    SOCIAL_CAMPAIGN_MANAGER_URL: 'https://sandbox-scm.bannerflow.com',
    SOCIAL_ACCOUNT_SERVICE_URL: 'https://sandbox-sas.bannerflow.com',
    SCHEDULE_MIGRATION_CLIENT_URL: 'https://sandbox-smc.bannerflow.com',
    USER_PROFILE_URL: 'https://bf-profileservice-sandbox-app.azurewebsites.net',
    GTM_ID: 'GTM-M2FZ5ZZ',
    LIST_SERVICE_URL: 'https://sandbox-api.bannerflow.com/list-service',
    ACCOUNT_ACCESS_URL: 'https://sandbox-api.bannerflow.com/account-access',
    ANALYTICS_FRONTEND_URL: 'https://sandbox-analytics.bannerflow.com',
    FEEDS_SUPREME_COMMANDER: 'https://sandbox-api.bannerflow.net/feeds-supreme-commander',
    FEEDS_CLIENT: 'https://sandbox-feeds.bannerflow.com'
};
