import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIErrorDialogService } from '@bannerflow/ui';
import { AppConfig } from '@config/app.config';
import { AuthService } from '@core/auth/auth.service';
import { SessionService } from '@core/services/internal/session.service';
import { PatchOperation } from '@shared/models/patch/patchOperation.model';
import { UserProfile } from '@shared/models/userprofile.model';
import { UserTitle } from '@shared/models/usertitle.model';
import { ApiService, BFHttpError } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private sessionService: SessionService,
        private errorDialogService: UIErrorDialogService
    ) {}

    private getWriteApiPrefix(): string {
        return `${AppConfig.config.USER_PROFILE_URL}/api`;
    }

    private getReadApiPrefix(): string {
        return `${AppConfig.config.ACCOUNT_ACCESS_URL}/api`;
    }

    public async getUserTitles(): Promise<UserTitle[]> {
        try {
            const headers: HttpHeaders = await this.authService.setupHeaders();
            return this.apiService.get(`${this.getReadApiPrefix()}/user-titles`, {
                anonymous: true,
                headers
            });
        } catch (error) {
            return this.handleError(error);
        }
    }
    public async getUserTitle(userId: string): Promise<UserTitle> {
        try {
            const headers: HttpHeaders = await this.authService.setupHeaders();
            return this.apiService
                .get(`${this.getReadApiPrefix()}/profiles/${userId}`, {
                    anonymous: true,
                    headers
                })
                .then(userprofile => userprofile.userTitle);
        } catch (error) {
            return this.handleError(error);
        }
    }
    public async updateUserTitle(userId: string, patchOperations: PatchOperation[]): Promise<void> {
        try {
            const headers: HttpHeaders = await this.authService.setupHeaders({}, 'application/json-patch+json');
            return this.apiService.patch(`${this.getWriteApiPrefix()}/profiles/${userId}`, patchOperations, {
                anonymous: true,
                headers
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async createUserProfile(userId: string, userTitleId: string): Promise<void> {
        const amAccountId: string = this.sessionService.user.account.amAccountId;

        const body = {
            userId,
            userTitleId,
            amAccountId
        };

        try {
            const headers: HttpHeaders = await this.authService.setupHeaders({});
            return this.apiService.post(`${this.getWriteApiPrefix()}/profiles`, body, {
                anonymous: true,
                headers
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async getUserProfiles(): Promise<UserProfile[]> {
        const amAccountId: string = this.sessionService.user.account.amAccountId;

        try {
            const headers: HttpHeaders = await this.authService.setupHeaders();
            return this.apiService.get(`${this.getReadApiPrefix()}/profiles?amAccountId=${amAccountId}`, {
                anonymous: true,
                headers
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    private async handleError(error: any): Promise<never> {
        if (error instanceof BFHttpError) {
            if (error.status === 404) {
                throw new Error('404 missing resource');
            } else {
                this.errorDialogService.show(
                    {
                        headerText: 'An error has occurred',
                        confirmText: 'Close'
                    },
                    error
                );
            }
        }

        return Promise.reject(error);
    }
}
